export const parseEmbeddedMarkdown = (data, key = 'bodyText') => {
  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data[key]?.links?.entries?.block

  if (contentLinks && data[key]?.json?.content) {
    data[key].json.content.forEach((item) => {
      if (item.nodeType === 'embedded-entry-block') {
        const link = contentLinks.find((link) => link?.sys?.id === item?.data?.target?.sys?.id)
        if (link?.markdownText && item?.data?.target) {
          item.data.target.fields = {
            ...item.data.target.fields,
            markdownText: link.markdownText,
          }
          item.data.target.sys = {
            ...item.data.target.sys,
            contentType: {
              sys: {
                id: 'moduleMarkdownText',
              },
            },
          }
        }
      }
    })
  }
}
